import { Culture } from "@/enums";

import NL from "@/assets/dictionary/nl.json";
import EN from "@/assets/dictionary/en.json";
import FR from "@/assets/dictionary/fr.json";

export type ApplicationDictionary = typeof NL;
export type Localizable<T> = Record<Culture, T>;

const dictionary: Localizable<ApplicationDictionary> = {
  [Culture.NL]: NL,
  [Culture.EN]: EN,
  [Culture.FR]: FR,
};

export default dictionary;
