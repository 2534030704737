<template>
  <div class="terms">
    <h1 style="margin-top: 0">Algemene Voorwaarden</h1>
    <p style="margin: 1em 0">
      Dit zijn de Algemene Voorwaarden voor Summit Registration &amp; Services
      B.V., gevestigd aan het adres Maliesingel 41 te (3581BK) Utrecht en
      ingeschreven bij de Kamer van Koophandel onder nummer 30142110, hierna te
      noemen “<b>Summit</b>”. Summit levert softwareoplossingen voor
      organisatoren van beurzen, congressen en andere evenementen en verhuurt in
      dit kader ook hardware voor onder meer bezoekersregistratie. Raadpleeg
      <a href="http://www.summit.nl/" target="_blank">http://www.summit.nl/</a>
      voor meer informatie.
    </p>
    <h2>Artikel 1. Definities</h2>
    <p>
      De met hoofdletter geschreven begrippen in deze Algemene Voorwaarden
      hebben (zowel in enkelvoud als in meervoud) de onderstaande betekenis:
    </p>
    <ol>
      <li style="list-style-type: '1.1.'">
        <p>
          <span class="bold">Algemene Voorwaarden:</span> de onderhavige
          voorwaarden inclusief bijlagen.
        </p>
      </li>
      <li style="list-style-type: '1.2.'">
        <p>
          <span class="bold">Opdrachtgever:</span> de natuurlijke persoon of
          rechtspersoon waarmee Summit de Overeenkomst heeft gesloten.
        </p>
      </li>
      <li style="list-style-type: '1.3.'">
        <p>
          <span class="bold">Diensten:</span> alle diensten die Summit op grond
          van de Overeenkomst aan Opdrachtgever levert, waaronder in ieder geval
          begrepen kan zijn: (i) het leveren van Software(-as-a-Service), (ii)
          het onderhouden van die Software en (iii) het verhuren van Hardware.
        </p>
      </li>
      <li style="list-style-type: '1.4.'">
        <p>
          <span class="bold">Hardware:</span> alle (roerende) zaken die Summit
          op grond van de Overeenkomst verhuurt aan Opdrachtgever, waaronder in
          ieder geval begrepen kan zijn: het hardware-apparaat SummitHost
          waarmee de bezoekers kunnen worden aangemeld, de codes kunnen worden
          gescand en badges kunnen worden geprint.
        </p>
      </li>
      <li style="list-style-type: '1.5.'">
        <p>
          <span class="bold">Intellectuele Eigendomsrechten:</span> alle rechten
          van intellectuele eigendom en daarmee verwante rechten, waaronder in
          ieder geval auteursrechten, databankrechten, rechten op domeinnamen,
          handelsnaamrechten, rechten op knowhow, merkenrechten, modelrechten,
          naburige rechten en octrooirechten.
        </p>
      </li>
      <li style="list-style-type: '1.6.'">
        <p>
          <span class="bold">Kantooruren:</span> de tijd van 9:00 tot 17:00 uur
          van maandag tot en met zondag, met uitzondering van officiële
          Nederlandse feestdagen.
        </p>
      </li>
      <li style="list-style-type: '1.7.'">
        <p>
          <span class="bold">Materialen:</span> alle werken, zoals websites en
          (web)applicaties, huisstijlen, logo’s, folders, brochures, leaflets,
          belettering, advertenties, marketing- en/of communicatieplannen,
          concepten, afbeeldingen, teksten, schetsen, documentatie, adviezen,
          rapporten en andere voortbrengselen van de geest, alsmede
          voorbereidend materiaal daarvan en (al dan niet gecodeerde) bestanden
          of gegevensdragers waarop de materialen zich bevinden.
        </p>
      </li>
      <li style="list-style-type: '1.8.'">
        <p>
          <span class="bold">Meerwerk:</span> aanvullende werkzaamheden of
          diensten die buiten de Overeenkomst vallen.
        </p>
      </li>
      <li style="list-style-type: '1.9.'">
        <p>
          <span class="bold">Overeenkomst:</span> het (door Opdrachtgever
          aanvaarde) aanbod op grond waarvan Summit zijn Diensten aan
          Opdrachtgever levert, met inbegrip van deze Algemene Voorwaarden en
          eventuele andere documenten en/of afspraken genoemd in het aanbod,
          zoals de Verwerkersovereenkomst.
        </p>
      </li>
      <li style="list-style-type: '1.10.'">
        <p>
          <span class="bold">Partij(en):</span> de partij(en) bij de
          Overeenkomst, Summit en Opdrachtgever gezamenlijk of afzonderlijk.
        </p>
      </li>
      <li style="list-style-type: '1.11.'">
        <p>
          <span class="bold">Software:</span> alle programmatuur die Summit op
          grond van de Overeenkomst beschikbaar stelt.
        </p>
      </li>
      <li style="list-style-type: '1.12.'">
        <p>
          <span class="bold">Spoedonderhoud:</span> onvoorzien onderhoud dat
          redelijkerwijs nodig is in verband met de beschikbaarheid,
          bruikbaarheid en/of veiligheid van de Diensten.
        </p>
      </li>
      <li style="list-style-type: '1.13.'">
        <p>
          <span class="bold">Verwerkersovereenkomst:</span> de
          verwerkersovereenkomst in Bijlage 1 van de Algemene Voorwaarden die
          onderdeel uitmaakt van de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '1.14.'">
        <p>
          <span class="bold">Website:</span>
          <a href="http://www.summit.nl/" target="_blank"
            >http://www.summit.nl/</a
          >
        </p>
      </li>
    </ol>
    <h2>Artikel 2. Totstandkoming</h2>
    <ol>
      <li style="list-style-type: '2.1.'">
        <p>
          Alle offertes en andere aanbiedingen van Summit zijn vrijblijvend.
          Summit is niet verplicht om een aanvaarding na het verstrijken van een
          eventueel genoemde geldigheidsduur in een offerte of aanbod aan te
          nemen, maar indien Summit daartoe overgaat, geldt de offerte of het
          aanbod alsnog als aanvaard.
        </p>
      </li>
      <li style="list-style-type: '2.2.'">
        <p>
          Als de aanvaarding door Opdrachtgever (al dan niet op ondergeschikte
          punten) afwijkt van het aanbod, is Summit daaraan niet gebonden. De
          Overeenkomst komt in dat geval niet overeenkomstig deze afwijkende
          aanvaarding tot stand, tenzij Summit expliciet anders aangeeft.
        </p>
      </li>
      <li style="list-style-type: '2.3.'">
        <p>
          Indien Opdrachtgever niet expliciet aangeeft akkoord te gaan met het
          aanbod van Summit, maar er desondanks mee instemt, of die indruk wekt,
          dat Summit werkzaamheden verricht die binnen de omschrijving van de
          aangeboden Diensten vallen, dan wordt het aanbod als aanvaard
          beschouwd.
        </p>
      </li>
      <li style="list-style-type: '2.4.'">
        <p>
          Eventuele algemene (inkoop)voorwaarden van Opdrachtgever worden
          uitdrukkelijk van hand gewezen. Aanvullende door Opdrachtgever
          verstrekte voorwaarden, afspraken of bepalingen zijn enkel van
          toepassing indien deze uitdrukkelijk schriftelijk tussen Partijen
          overeengekomen zijn.
        </p>
      </li>
      <li style="list-style-type: '2.5.'">
        <p>
          In geval van strijdigheid van verschillende documenten of de bijlagen
          daarvan, geldt de volgende rangorde:
        </p>
        <ol>
          <li style="list-style-type: 'a.'">
            <p>aanvullende schriftelijke en ondertekende afspraken;</p>
          </li>
          <li style="list-style-type: 'b.'">
            <p>geaccordeerde offerte of aanbod;</p>
          </li>
          <li style="list-style-type: 'c.'">
            <p>de Verwerkersovereenkomst;</p>
          </li>
          <li style="list-style-type: 'd.'">
            <p>de Algemene Voorwaarden.</p>
          </li>
        </ol>
      </li>
    </ol>
    <h2>Artikel 3. Uitvoering van de Overeenkomst</h2>
    <ol>
      <li style="list-style-type: '3.1.'">
        <p>
          Nadat de Overeenkomst tot stand is gekomen, spant Summit zich ervoor
          in om de Overeenkomst naar beste kunnen na te komen onder toepassing
          van voldoende zorgvuldigheid en vakmanschap. Door Summit
          bekendgemaakte termijnen zijn indicatief en gelden niet als fatale
          termijnen. Indien expliciet wordt aangegeven dat een termijn fataal
          is, dan is Summit niet in verzuim totdat hij in gebreke is gesteld en
          ook binnen de redelijke termijn niet alsnog is nagekomen.
        </p>
      </li>
      <li style="list-style-type: '3.2.'">
        <p>
          Opdrachtgever zal aan Summit alle ondersteuning bieden die nodig en
          wenselijk is om een juiste en tijdige levering van de Diensten
          mogelijk te maken. In ieder geval zal Opdrachtgever:
        </p>
        <ol>
          <li style="list-style-type: 'a.'">
            <p>
              alle informatie verstrekken waarvan Summit aangeeft dat deze
              noodzakelijk zijn, of waarvan Opdrachtgever redelijkerwijs moet
              begrijpen dat deze noodzakelijk zijn voor de levering van de
              Diensten;
            </p>
          </li>
          <li style="list-style-type: 'b.'">
            <p>
              aan Summit toegang verlenen tot alle plaatsen, diensten en
              accounts onder zijn beheer indien en voor zover dit nodig is voor
              de levering van de Diensten.
            </p>
          </li>
        </ol>
      </li>
      <li style="list-style-type: '3.3.'">
        <p>
          Summit zal rekening houden met redelijke verzoeken van Opdrachtgever
          bij de uitvoering van de Overeenkomst, maar is niet verplicht gehoor
          te geven aan een dergelijk verzoek. Summit heeft het recht meerkosten
          te rekenen voor het uitvoeren van een dergelijk verzoek.
        </p>
      </li>
      <li style="list-style-type: '3.4.'">
        <p>
          Summit heeft het recht om derden in te schakelen bij de uitvoering van
          de Overeenkomst. Eventuele hiermee verband houdende kosten komen
          alleen voor rekening van Opdrachtgever indien dat vooraf is
          overeengekomen.
        </p>
      </li>
      <li style="list-style-type: '3.5.'">
        <p>
          Indien medewerkers van Summit bij de uitvoering van de Overeenkomst
          werkzaamheden verrichten ten kantore van Opdrachtgever of op een
          andere door Opdrachtgever aangewezen locatie, zal Opdrachtgever
          kosteloos alle ondersteuning en faciliteiten bieden ten behoeve van de
          uitoefening van de werkzaamheden.
        </p>
      </li>
    </ol>
    <h2>Artikel 4. Meerwerk</h2>
    <ol>
      <li style="list-style-type: '4.1.'">
        <p>
          Indien Opdrachtgever verzoekt om aanvullende werkzaamheden of Diensten
          die buiten de Overeenkomst vallen, zullen Partijen daarover in overleg
          treden en kan Summit een aanvullende offerte opstellen. Summit zal het
          Meerwerk pas uitvoeren na aanvaarding van de offerte door
          Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '4.2.'">
        <p>
          Voor Meerwerk waarvan Summit kan aantonen dat het redelijkerwijs
          noodzakelijk is voor de levering van de Diensten, of dat
          redelijkerwijs volgt uit de instructies van Opdrachtgever, heeft
          Summit geen toestemming nodig. Dergelijke werkzaamheden worden op
          basis van nacalculatie uitgevoerd tegen het uurtarief van Summit dat
          geldt op het moment van de uitvoering van de werkzaamheden.
        </p>
      </li>
    </ol>
    <h2>Artikel 5. Oplevering en aanvaarding</h2>
    <ol>
      <li style="list-style-type: '5.1.'">
        <p>
          Indien Summit Diensten op maat levert aan Opdrachtgever, zal Summit
          het maatwerk opleveren wanneer dit in zijn professionele opinie
          voldoet aan de omschrijving in de Overeenkomst of geschikt is voor
          gebruik. Opdrachtgever zal het opgeleverde binnen zeven dagen na
          oplevering evalueren en goed- of afkeuren conform de in de
          omschrijving in de Overeenkomst genoemde of nader overeengekomen
          opleveringscriteria. Indien Opdrachtgever niet binnen deze periode het
          opgeleverde afkeurt, wordt het opgeleverde geacht te zijn aanvaard.
        </p>
      </li>
      <li style="list-style-type: '5.2.'">
        <p>
          Indien de Diensten in fasen worden opgeleverd, dient Opdrachtgever na
          oplevering van elke fase de goed- of afkeuring van het deel van de
          Diensten van die fase te geven op de wijze zoals in het vorige lid
          bepaald. Opdrachtgever kan een goed- of afkeuring in een latere fase
          niet baseren op aspecten die in een eerdere fase goedgekeurd zijn.
        </p>
      </li>
      <li style="list-style-type: '5.3.'">
        <p>
          Indien Opdrachtgever het opgeleverde geheel of gedeeltelijk afkeurt,
          zal Summit zich inspannen de reden van afkeuring zo snel mogelijk weg
          te nemen. Dit kan Summit doen door het resultaat te reviseren of
          gemotiveerd aan te geven waarom de reden niet opgaat. Opdrachtgever
          heeft vervolgens veertien dagen om de revisie of motivatie goed of af
          te keuren. In geval van afkeuring wordt de overeenkomst beëindigd en
          de reeds gemaakte kosten door Summit in rekening gebracht.
        </p>
      </li>
      <li style="list-style-type: '5.4.'">
        <p>
          Opdrachtgever mag de Diensten slechts afkeuren op grond van
          substantiële afwijkingen van de functionele specificatie uit de
          Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '5.5.'">
        <p>
          Indien bezwaren slechts ondergeschikte aspecten betreffen, worden de
          Diensten geacht te zijn aanvaard onder het voorbehoud dat deze
          bezwaren alsnog binnen een redelijke termijn door Summit worden
          opgeheven.
        </p>
      </li>
      <li style="list-style-type: '5.6.'">
        <p>
          Voor fouten ontdekt na aanvaarding bestaat geen aansprakelijkheid voor
          Summit, tenzij Summit deze kende of had moeten kennen bij oplevering.
        </p>
      </li>
    </ol>
    <h2>Artikel 6. Aanpassingen en verbeteren</h2>
    <ol>
      <li style="list-style-type: '6.1.'">
        <p>
          Summit kan de Diensten op basis van zijn professionele opinie
          aanpassen, bijvoorbeeld om fouten op te lossen, nieuwe
          functionaliteiten toe te voegen of de prestaties te verbeteren. Summit
          kan over dergelijke aanpassingen vooraf overleg voeren met
          Opdrachtgever, maar de eindbeslissing over het al dan niet doorvoeren
          van de aanpassing wordt genomen door Summit.
        </p>
      </li>
      <li style="list-style-type: '6.2.'">
        <p>
          Indien aanpassingen naar het oordeel van Summit tot een wezenlijke
          verandering in de functionaliteit van de Diensten leiden, streeft
          Summit ernaar om de aanpassingen van tevoren per e-mail aan te
          kondigen bij Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '6.3.'">
        <p>
          Summit zal zich inspannen om eventuele fouten in de Diensten te
          herstellen, maar is hierbij mede afhankelijk van zijn leveranciers.
          Summit heeft het recht om bepaalde updates of upgrades van
          leveranciers niet te installeren als dit naar zijn oordeel de werking
          van de Diensten niet ten goede komt.
        </p>
      </li>
      <li style="list-style-type: '6.4.'">
        <p>
          Als het doorvoeren van aanpassingen en verbeteringen tot een beperking
          van de beschikbaarheid van de Diensten kan leiden, wordt dit zoveel
          mogelijk buiten Kantooruren uitgevoerd. Spoedonderhoud kan op ieder
          moment worden verricht en wordt niet vooraf aangekondigd.
        </p>
      </li>
    </ol>
    <h2>Artikel 7. Ondersteuning</h2>
    <ol>
      <li style="list-style-type: '7.1.'">
        <p>
          Summit spant zich in Opdrachtgever te ondersteunen met betrekking tot
          vragen over het gebruik en beheer van de Diensten, alsmede technische
          problemen die verband houden met de Diensten.
        </p>
      </li>
      <li style="list-style-type: '7.2.'">
        <p>
          De ondersteuning zoals omschreven in het voorgaande lid wordt
          aangeboden via een helpdesk die tijdens Kantooruren per e-mail of per
          telefoon bereikbaar is.
        </p>
      </li>
      <li style="list-style-type: '7.3.'">
        <p>
          Summit streeft ernaar om helpdeskverzoeken binnen een redelijke
          termijn af te handelen. De tijd die nodig is voor het oplossen van
          meldingen kan variëren.
        </p>
      </li>
    </ol>
    <h2>Artikel 8. Beschikbaarheid en back-ups</h2>
    <ol>
      <li style="list-style-type: '8.1.'">
        <p>
          Summit zal zich inspannen om de Diensten zo goed mogelijk te leveren
          en beschikbaar te laten zijn. Opdrachtgever kan enkel aanspraak maken
          op specifieke service levels, zoals een bepaalde beschikbaarheid of
          reactietijd, indien dit schriftelijk is overeengekomen. Bijvoorbeeld
          in een service level agreement.
        </p>
      </li>
      <li style="list-style-type: '8.2.'">
        <p>
          Als naar het oordeel van Summit gevaar ontstaat voor het functioneren
          van de computersystemen of het netwerk van Summit of derden,
          bijvoorbeeld in het geval van een (d)dos-aanval of activiteiten van
          malware, heeft Summit het recht om alle maatregelen te nemen die hij
          redelijkerwijs nodig acht om het gevaar af te wenden en schade te
          beperken of te voorkomen. Dit kan resulteren in beperkte
          beschikbaarheid.
        </p>
      </li>
      <li style="list-style-type: '8.3.'">
        <p>
          Summit zal periodiek reservekopieën (back-ups) maken van bepaalde
          gegevens van Opdrachtgever op systemen van Summit of derden, en deze
          gegevens tegen een redelijke vergoeding op verzoek aan Opdrachtgever
          beschikbaar stellen. Deze reservekopieën kunnen na beëindiging van de
          Overeenkomst op elk moment vernietigd worden door Summit. Het is de
          verantwoordelijkheid van Opdrachtgever om tijdig te verzoeken om een
          reservekopie bij beëindiging van de Overeenkomst. Tenzij partijen
          nadere afspraken maken over het maken van reservekopieën, is Summit
          volledig vrij in het vaststellen van onder meer: de tijdstippen waarop
          reservekopieën worden gemaakt, de frequentie waarmee reservekopieën
          worden gemaakt, de bewaartermijn van de reservekopieën.
        </p>
      </li>
      <li style="list-style-type: '8.4.'">
        <p>
          Tenzij schriftelijk anders overeengekomen worden eventuele
          reservekopieën gemaakt via een geautomatiseerd proces en worden deze
          reservekopieën steekproefsgewijs getest of ze naar behoren werken.
          Enkel wanneer partijen schriftelijk overeenkomen dat alle of bepaalde
          reservekopieën handmatig door Summit getest worden, kan Summit ervoor
          instaan dat de handmatig geteste reservekopieën naar behoren werken en
          terug gezet kunnen worden.
        </p>
      </li>
    </ol>
    <h2>Artikel 9. Gebruiksregels</h2>
    <ol>
      <li style="list-style-type: '9.1.'">
        <p>
          Het is Opdrachtgever verboden om de Diensten te gebruiken op een
          manier die in strijd is met deze voorwaarden of met toepasselijke wet-
          en regelgeving. Daarnaast is het uitdrukkelijk niet toegestaan om de
          Diensten te gebruiken op een manier die hinder of schade kan
          veroorzaken voor Summit of derden.
        </p>
      </li>
      <li style="list-style-type: '9.2.'">
        <p>
          Indien Summit constateert dat Opdrachtgever deze voorwaarden of de wet
          overtreedt, of een klacht hierover ontvangt, mag Summit ingrijpen om
          de overtreding te beëindigen. Summit zal dan de toegang tot de
          betreffende informatie blokkeren.
        </p>
      </li>
      <li style="list-style-type: '9.3.'">
        <p>
          Indien naar het oordeel van Summit hinder, schade of een ander gevaar
          ontstaat voor het functioneren van de computersystemen of het netwerk
          van Summit of derden en/of van de dienstverlening via internet, in het
          bijzonder door overmatig verzenden van e-mail of andere gegevens,
          lekken van persoonsgegevens of activiteiten van virussen, trojans en
          vergelijkbare software, is Summit gerechtigd alle maatregelen te nemen
          die hij redelijkerwijs nodig acht om dit gevaar af te wenden dan wel
          te voorkomen. Deze maatregelen bevatten, maar zijn niet beperkt tot,
          het opschorten van de Diensten en beëindiging van de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '9.4.'">
        <p>
          Summit is te allen tijde gerechtigd om aangifte te doen van
          geconstateerde strafbare feiten en zal zijn medewerking verlenen aan
          bevoegd gegeven bevelen. Daarnaast is Summit gerechtigd om
          identificerende gegevens van Opdrachtgever af te geven aan een derde
          die klaagt dat Opdrachtgever inbreuk maakt op diens rechten of deze
          voorwaarden, mits:
        </p>
        <ol>
          <li style="list-style-type: 'a.'">
            <p>
              het voldoende aannemelijk is dat de informatie, op zichzelf
              beschouwd, jegens de derde onrechtmatig of schadelijk is;
            </p>
          </li>
          <li style="list-style-type: 'b.'">
            <p>
              de derde redelijkerwijs een reëel belang heeft bij de verkrijging
              van de gegevens;
            </p>
          </li>
          <li style="list-style-type: 'c.'">
            <p>
              het aannemelijk is dat er in het concrete geval redelijkerwijs
              geen minder ingrijpende mogelijkheid voorhanden is om de gegevens
              de achterhalen.
            </p>
          </li>
        </ol>
      </li>
      <li style="list-style-type: '9.5.'">
        <p>
          Opdrachtgever is verplicht om alle redelijke aanwijzingen van Summit
          te volgen die betrekking hebben op het gebruik van de Diensten.
        </p>
      </li>
      <li style="list-style-type: '9.6.'">
        <p>
          Summit kan de schade als gevolg van overtredingen van de regels uit
          dit artikel op Opdrachtgever verhalen. Opdrachtgever vrijwaart Summit
          van alle aanspraken van derden die betrekking hebben op schade als
          gevolg van een schending van de regels uit dit artikel.
        </p>
      </li>
    </ol>
    <h2>Artikel 10. Ticketverkoop</h2>
    <ol>
      <li style="list-style-type: '10.1.'">
        <p>
          Als Opdrachtgever de Diensten gebruikt om een evenement te promoten
          waarvoor zij (betaalde) tickets aanbiedt, dan is Summit geen partij
          bij de (koop) overeenkomst tussen Opdrachtgever en de persoon (of
          juridische entiteit) die de aangeboden tickets koopt. Summit treedt
          alleen op als tussenpersoon bij de totstandkoming van een dergelijke
          overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '10.2.'">
        <p>
          Bij het aanbieden van tickets is Opdrachtgever verantwoordelijk voor
          de naleving van de toepasselijke wetgeving, waaronder en niet
          uitsluitend wordt verstaan: de consumenten- en belastingwetgeving die
          van toepassing is op Opdrachtgever en de personen (of juridische
          entiteiten) die de kaartjes kopen.
        </p>
      </li>
      <li style="list-style-type: '10.3.'">
        <p>
          Summit levert geen betaaldiensten. Bij het aanbieden van tickets
          waarvoor een vergoeding verschuldigd is via de Diensten, wordt de
          betaling afgehandeld door een externe betalingsdienstaanbieder. Summit
          is niet verantwoordelijk voor fouten van vertragingen in de betaling.
        </p>
      </li>
      <li style="list-style-type: '10.4.'">
        <p>
          Bij het aanbieden van tickets via de Diensten, is Opdrachtgever
          verantwoordelijk voor het toepassen en betalen van het juiste bedrag
          aan btw en andere heffingen opgelegd door de overheid of toepasselijke
          wetgeving voor Opdrachtgever of de personen (of entiteiten) die de
          tickets kopen.
        </p>
      </li>
      <li style="list-style-type: '10.5.'">
        <p>
          Wanneer een ticket voor een evenement wordt gekocht (of tickets worden
          gekocht) via de Diensten en de persoon (of juridische entiteit) die de
          kaartjes koopt een (deel van het) betaalde bedrag terugvordert, dan
          zal Opdrachtgever Summit dit bedrag en alle daarop betrekking hebbende
          gerelateerde kosten terugbetalen. Summit stuurt Opdrachtgever een
          factuur voor een dergelijke terugbetaling.
        </p>
      </li>
    </ol>
    <h2>Artikel 11. Beveiliging en privacy</h2>
    <ol>
      <li style="list-style-type: '11.1.'">
        <p>
          Summit zal zich inspannen om de Diensten te beveiligen tegen misbruik
          en ongeautoriseerde toegang tot gegevens van Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '11.2.'">
        <p>
          Bij de uitvoering van de Overeenkomst is het mogelijk dat Summit in
          opdracht van Opdrachtgever persoonsgegevens verwerkt, waarbij
          Opdrachtgever als verwerkingsverantwoordelijke is aan te merken en
          Summit als verwerker. De Verwerkersovereenkomst in Bijlage 1 van deze
          Algemene Voorwaarden is van toepassing van de verwerking van
          persoonsgegevens van Summit voor Opdrachtgever. Genoemde termen hebben
          de betekenis zoals vastgelegd in de toepasselijke privacywetgeving.
        </p>
      </li>
      <li style="list-style-type: '11.3.'">
        <p>
          Opdrachtgever garandeert dat iedere eventuele verstrekking van
          (persoons)gegevens aan Summit rechtmatig is en dat de verwerking van
          deze gegevens conform de Overeenkomst niet in strijd is met enige
          toepasselijke privacywet- en regelgeving.
        </p>
      </li>
    </ol>
    <h2>Artikel 12. Intellectuele Eigendomsrechten</h2>
    <ol>
      <li style="list-style-type: '12.1.'">
        <p>
          Alle Intellectuele Eigendomsrechten op de Diensten en eventuele
          Materialen die op grond van de Overeenkomst door Summit worden
          geleverd berusten uitsluitend bij Summit of diens licentiegevers.
        </p>
      </li>
      <li style="list-style-type: '12.2.'">
        <p>
          Opdrachtgever krijgt van Summit de niet-exclusieve, niet-overdraagbare
          en niet-sublicentieerbare gebruiksrechten ten aanzien van de Diensten
          en eventuele Materialen voor zover deze voortvloeien uit de
          Overeenkomst of welke anderszins schriftelijk zijn toegekend. De
          gebruiksrechten gelden voor de duur van de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '12.3.'">
        <p>
          Opdrachtgever is niet gerechtigd om wijzigingen aan te brengen in de
          Software en heeft geen recht op een kopie van de bronbestanden van de
          Software.
        </p>
      </li>
      <li style="list-style-type: '12.4.'">
        <p>
          Summit kan (technische) maatregelen nemen ter bescherming van de
          Diensten. Indien Summit dergelijke beveiligingsmaatregelen heeft
          genomen, is het Opdrachtgever niet toegestaan om deze beveiliging te
          ontwijken of te verwijderen.
        </p>
      </li>
      <li style="list-style-type: '12.5.'">
        <p>
          Het is Opdrachtgever niet toegestaan om enige aanduiding van
          Intellectuele Eigendomsrechten uit de Software te verwijderen of aan
          te passen. Het is tevens niet toegestaan om aanduidingen omtrent het
          vertrouwelijke karakter uit de Software te verwijderen.
        </p>
      </li>
    </ol>
    <h2>Artikel 13. Geheimhouding</h2>
    <ol>
      <li style="list-style-type: '13.1.'">
        <p>
          Partijen zullen informatie die zij voor, tijdens of na de uitvoering
          van de Overeenkomst aan elkaar verstrekken of in de Diensten
          verwerken, vertrouwelijk behandelen wanneer deze informatie is
          gemarkeerd als vertrouwelijk of waarvan van tevoren expliciet wordt
          aangegeven dat het vertrouwelijk is. Partijen leggen deze verplichting
          tevens op aan hun werknemers alsmede aan door hen ingeschakelde derden
          ter uitvoering van de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '13.2.'">
        <p>
          Partijen zullen commerciële afspraken, zoals overeengekomen prijzen,
          in ieder geval vertrouwelijk behandelen.
        </p>
      </li>
      <li style="list-style-type: '13.3.'">
        <p>
          De verplichting tot vertrouwelijk behandelen blijft ook bestaan na
          beëindiging van de Overeenkomst om welke reden dan ook, en wel voor
          zolang als de verstrekkende partij redelijkerwijs aanspraak kan maken
          op het vertrouwelijke karakter van de informatie.
        </p>
      </li>
      <li style="list-style-type: '13.4.'">
        <p>
          Partijen zullen ten aanzien van ontvangen vertrouwelijke informatie
          redelijke maatregelen in acht nemen voor een veilige bewaring of
          opslag van de vertrouwelijke informatie. Partijen zullen ten aanzien
          van ontvangen vertrouwelijke informatie minimaal hetzelfde niveau van
          beveiliging hanteren als partijen voor hun eigen vertrouwelijke
          informatie hanteren.
        </p>
      </li>
    </ol>
    <h2>Artikel 14. Prijzen en betalingsvoorwaarden</h2>
    <ol>
      <li style="list-style-type: '14.1.'">
        <p>
          Opdrachtgever betaalt Summit een vergoeding voor de Dienst(en) zoals
          overeengekomen of vermeld in het aanbod. Tenzij bij een bedrag
          uitdrukkelijk anders is vermeld zijn alle door Summit genoemde prijzen
          exclusief omzetbelasting (btw) en andere heffingen die van
          overheidswege worden opgelegd.
        </p>
      </li>
      <li style="list-style-type: '14.2.'">
        <p>
          Alle prijzen in aanbiedingen, prijslijst(en) en/of overige
          communicatiemiddelen van Summit zijn onder voorbehoud van programmeer-
          en typefouten. Indien een prijs is gebaseerd op door Opdrachtgever
          verstrekte gegevens en deze gegevens blijken onjuist te zijn, dan
          heeft Summit het recht de prijzen hierop aan te passen, ook nadat de
          Overeenkomst reeds tot stand is gekomen.
        </p>
      </li>
      <li style="list-style-type: '14.3.'">
        <p>
          Summit is gerechtigd de door hem gehanteerde en/of de overeengekomen
          prijzen telkens bij verlenging van de Overeenkomst en/of éénmaal per
          kalenderjaar, aan te passen op basis van inflatie, loonindexeringen op
          basis van een cao, een stijging van kosten of veranderde
          marktomstandigheden. Indien een toeleverancier van Summit zijn prijzen
          verhoogt, dan is Summit steeds gerechtigd deze verhoging per direct
          door te berekenen aan Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '14.4.'">
        <p>
          Summit zal voor alle verschuldigde bedragen een factuur sturen en is
          daarbij gerechtigd om vooraf en elektronisch te factureren. Als
          Opdrachtgever bezwaar maakt tegen (de hoogte) van een factuur, schort
          dit de betalingsplicht niet op.
        </p>
      </li>
      <li style="list-style-type: '14.5.'">
        <p>
          Indien er Meerwerk is overeengekomen, worden de verschuldigde bedragen
          na het uitvoeren van het Meerwerk gefactureerd, tenzij anders
          overeengekomen.
        </p>
      </li>
      <li style="list-style-type: '14.6.'">
        <p>
          Voor alle facturen geldt een betalingstermijn van 30 dagen, tenzij de
          factuur een andere betalingstermijn vermeldt of schriftelijk een
          andere termijn is overeengekomen.
        </p>
      </li>
      <li style="list-style-type: '14.7.'">
        <p>
          Indien Opdrachtgever na de betalingstermijn nog niet volledig heeft
          betaald, zal Summit Opdrachtgever alsnog de mogelijkheid geven om het
          factuurbedrag binnen veertien (14) dagen te betalen. Indien
          Opdrachtgever ook na deze termijn niet volledig heeft betaald, is
          Opdrachtgever automatisch in verzuim zonder dat hiervoor een
          ingebrekestelling is vereist. Summit heeft in dat geval het recht om
          de levering van de Diensten geheel of gedeeltelijk op te schorten.
          Summit is niet aansprakelijk voor schade die Opdrachtgever hierdoor
          lijdt.
        </p>
      </li>
      <li style="list-style-type: '14.8.'">
        <p>
          Bij een niet-tijdige betaling is Opdrachtgever, naast het
          verschuldigde bedrag en de daarop verschenen handelsrente, gehouden
          tot een volledige vergoeding van zowel buitengerechtelijke als
          gerechtelijke incassokosten, met inbegrip van de kosten voor
          advocaten, juristen, deurwaarders en incassobureaus.
        </p>
      </li>
      <li style="list-style-type: '14.9.'">
        <p>
          Alle vorderingen van Summit op Opdrachtgever zijn direct opeisbaar
          indien het faillissement van Opdrachtgever is aangevraagd,
          Opdrachtgever in staat van faillissement verkeert, Opdrachtgever
          surseance van betaling aanvraagt of krijgt verleend, de activiteiten
          van Opdrachtgever worden beëindigd, dan wel wanneer de onderneming van
          Opdrachtgever wordt geliquideerd.
        </p>
      </li>
      <li style="list-style-type: '14.10.'">
        <p>
          Alle door Summit genoemde prijzen zijn in euro’s en exclusief btw en
          andere heffingen welke van overheidswege worden opgelegd.
        </p>
      </li>
    </ol>
    <h2>Artikel 15. Aansprakelijkheid</h2>
    <ol>
      <li style="list-style-type: '15.1.'">
        <p>
          De aansprakelijkheid van Summit voor schade als gevolg van een
          tekortkoming in de nakoming van de Overeenkomst, een onrechtmatige
          daad, het schenden van een garantieverplichting of anderszins, is per
          gebeurtenis (waarbij een reeks van opeenvolgende gebeurtenissen geldt
          als één gebeurtenis) beperkt tot vergoeding van directe schade met een
          maximum dat gelijk is aan het bedrag (exclusief btw) dat Opdrachtgever
          op grond van de Overeenkomst heeft betaald aan Summit gedurende 6
          maanden voorafgaand aan het ontstaan van de schade.
        </p>
      </li>
      <li style="list-style-type: '15.2.'">
        <p>
          Onder directe schade wordt uitsluitend verstaan alle schade bestaande
          uit:
        </p>
        <ol>
          <li style="list-style-type: 'a.'">
            <p>
              schade direct toegebracht aan stoffelijke zaken (“zaakschade”);
            </p>
          </li>
          <li style="list-style-type: 'b.'">
            <p>
              redelijke en aantoonbare kosten die Opdrachtgever heeft moeten
              maken om Summit er toe te manen de Overeenkomst (weer) deugdelijk
              na te komen, tenzij de gebrekkige prestatie niet aan Summit
              toegerekend kan worden;
            </p>
          </li>
          <li style="list-style-type: 'c.'">
            <p>
              redelijke kosten ter vaststelling van de oorzaak en de omvang van
              de directe schade;
            </p>
          </li>
          <li style="list-style-type: 'd.'">
            <p>
              redelijke en aantoonbare kosten die Opdrachtgever heeft gemaakt
              ter voorkoming of beperking van de directe schade, voor zover
              Opdrachtgever aantoont dat deze kosten hebben geleid tot beperking
              van de directe schade;
            </p>
          </li>
          <li style="list-style-type: 'e.'">
            <p>
              redelijke en aantoonbare kosten voor het laten uitvoeren van de
              Overeenkomst door een derde, indien Summit na aanmaning van
              Opdrachtgever niet binnen de in de aanmaning gestelde redelijke
              termijn (weer) deugdelijk nakomt.
            </p>
          </li>
        </ol>
      </li>
      <li style="list-style-type: '15.3.'">
        <p>
          Summit is in geen geval aansprakelijk voor vergoeding van indirecte
          schade of gevolgschade, zoals schade wegens gederfde omzet of winst,
          vertragingsschade, schade wegens verlies van gegevens en schade wegens
          gemiste besparingen.
        </p>
      </li>
      <li style="list-style-type: '15.4.'">
        <p>
          De voornoemde beperking van aansprakelijkheid geldt niet voor zover de
          schade het gevolg is van opzet of bewuste roekeloosheid van het
          management van Summit, dan wel voor zover de aansprakelijkheid van
          Summit anderszins op grond van de toepasselijke wetgeving niet beperkt
          kan worden.
        </p>
      </li>
      <li style="list-style-type: '15.5.'">
        <p>
          De aansprakelijkheid van Summit wegens toerekenbare tekortkoming in de
          nakoming van de Overeenkomst ontstaat slechts indien Opdrachtgever
          Summit onverwijld en deugdelijk schriftelijk in gebreke stelt,
          stellende daarbij een redelijke termijn ter zuivering van de
          tekortkoming, en Summit ook na die termijn toerekenbaar in de nakoming
          van zijn
        </p>
        <p>
          verplichtingen tekort blijft schieten. De ingebrekestelling dient een
          zo gedetailleerd mogelijke omschrijving van de tekortkoming te
          bevatten, zodat Summit in staat is adequaat te reageren.
        </p>
      </li>
      <li style="list-style-type: '15.6.'">
        <p>
          Voorwaarde voor het ontstaan van enig recht op schadevergoeding is dat
          Opdrachtgever de schade uiterlijk binnen 30 dagen na ontdekking
          schriftelijk bij Summit meldt.
        </p>
      </li>
    </ol>
    <h2>Artikel 16. Overmacht</h2>
    <ol>
      <li style="list-style-type: '16.1.'">
        <p>
          Summit kan niet tot nakoming van enige verplichting uit de
          Overeenkomst worden gehouden indien de nakoming is verhinderd als
          gevolg van overmacht. Summit is niet aansprakelijk voor enige schade
          die het gevolg is van overmacht.
        </p>
      </li>
      <li style="list-style-type: '16.2.'">
        <p>
          Van overmacht is in ieder geval sprake bij stroomstoringen,
          internetstoringen, storingen in de telecommunicatie-infrastructuur,
          netwerkaanvallen (waaronder (d)dos-aanvallen), aanvallen van malware
          of andere kwaadaardige software, binnenlandse onlusten, natuurgeweld,
          terreur, mobilisatie, oorlog, in- en uitvoerbelemmeringen, stakingen,
          stagnatie in toelevering, brand, overstromingen en het geval waarin
          Summit door zijn toeleveranciers, ongeacht de reden daartoe, niet tot
          nakoming in staat wordt gesteld.
        </p>
      </li>
      <li style="list-style-type: '16.3.'">
        <p>
          Indien een overmachtssituatie langer dan 90 dagen heeft geduurd,
          hebben beide Partijen het recht om de Overeenkomst per direct
          schriftelijk op te zeggen. De Diensten die in dat geval door Summit
          zijn geleverd voordat de overmachtssituatie zich voordeed en gedurende
          de overmachtssituatie, worden naar verhouding afgerekend.
        </p>
      </li>
    </ol>
    <h2>Artikel 17. Duur en einde</h2>
    <ol>
      <li style="list-style-type: '17.1.'">
        <p>
          De Overeenkomst wordt aangegaan voor de periode die wordt genoemd in
          de offerte of het aanbod van Summit.
        </p>
      </li>
      <li style="list-style-type: '17.2.'">
        <p>
          Als de duur van de Overeenkomst niet in de offerte of het aanbod wordt
          genoemd, wordt de Overeenkomst geacht te zijn aangegaan voor een
          periode van 12 maanden, tenzij de Overeenkomst eindigt door een vooraf
          vastgestelde taak.
        </p>
      </li>
      <li style="list-style-type: '17.3.'">
        <p>
          Indien de Overeenkomst een duurovereenkomst is, kan deze tegen het
          einde van de looptijd door beide Partijen worden opgezegd met
          inachtneming van een opzegtermijn van 3 maanden. Bij het uitblijven
          van een dergelijke opzegging wordt de Overeenkomst telkens
          stilzwijgend verlengd met de initiële looptijd van de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '17.4.'">
        <p>
          Summit mag de Overeenkomst per direct schriftelijk opschorten of
          opzeggen, zonder dat daarvoor een ingebrekestelling is vereist, indien
          Opdrachtgever in verzuim is met betrekking tot een wezenlijke
          verplichting onder de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '17.5.'">
        <p>
          Summit mag de Overeenkomst per direct schriftelijk opschorten of
          opzeggen, zonder dat daarvoor een ingebrekestelling is vereist, indien
          het faillissement van Opdrachtgever is aangevraagd, Opdrachtgever in
          staat van faillissement verkeert, Opdrachtgever surseance van betaling
          aanvraagt of krijgt verleend, de activiteiten van Opdrachtgever worden
          beëindigd, dan wel wanneer de onderneming van Opdrachtgever wordt
          geliquideerd.
        </p>
      </li>
      <li style="list-style-type: '17.6.'">
        <p>
          Wanneer Summit de nakoming van de Overeenkomst opschort, behoudt
          Summit zijn aanspraken op grond van de Overeenkomst en de
          toepasselijke wet- en regelgeving.
        </p>
      </li>
      <li style="list-style-type: '17.7.'">
        <p>
          In het geval dat de Overeenkomst wordt beëindigd zijn de vorderingen
          van Summit op Opdrachtgever onmiddellijk opeisbaar (ongeacht de reden
          voor de beëindiging).
        </p>
      </li>
      <li style="list-style-type: '17.8.'">
        <p>
          Indien de Overeenkomst wordt ontbonden, blijft Opdrachtgever de reeds
          gefactureerde bedragen verschuldigd en ontstaan er geen verplichtingen
          tot ongedaanmaking.
        </p>
      </li>
      <li style="list-style-type: '17.9.'">
        <p>
          Opdrachtgever kan uitsluitend het deel van de Overeenkomst ontbinden
          dat nog niet door Summit is uitgevoerd.
        </p>
      </li>
    </ol>
    <h2>Artikel 18. Wijziging algemene voorwaarden</h2>
    <ol>
      <li style="list-style-type: '18.1.'">
        <p>
          Summit heeft het recht om deze Algemene Voorwaarden te wijzigen of aan
          te vullen. Summit zal de wijziging of aanvulling ten minste 30 dagen
          vóór inwerkingtreding per e-mail of via de Website aankondigen bij
          Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '18.2.'">
        <p>
          Als Opdrachtgever de wijzigingen of aanvullingen niet wenst te
          accepteren, kan Opdrachtgever binnen 14 dagen na bekendmaking
          gemotiveerd bezwaar maken, waarna Summit de aanpassing of aanvulling
          zal heroverwegen. Als Summit besluit om vast te houden aan de
          wijziging of aanvulling, kan Opdrachtgever de Overeenkomst
          schriftelijk opzeggen tegen de datum waarop de wijziging in werking
          treedt.
        </p>
      </li>
      <li style="list-style-type: '18.3.'">
        <p>
          Beide Partijen zullen hun volledige medewerking verlenen bij
          wijzigingen of aanvullingen van deze Algemene Voorwaarden die
          noodzakelijk zijn vanwege nieuwe of veranderde wetgeving. Dergelijke
          wijzigingen of aanvullingen zullen in overleg tussen Partijen worden
          doorgevoerd, zonder dat Opdrachtgever de mogelijkheid heeft de
          Overeenkomst op te zeggen.
        </p>
      </li>
      <li style="list-style-type: '18.4.'">
        <p>
          De in artikel 18.2. en 18.3. beschreven procedure geldt niet voor
          wijzigingen van ondergeschikt belang. Dergelijke wijzigingen mogen
          zonder aankondiging door Summit worden doorgevoerd.
        </p>
      </li>
    </ol>
    <h2>Artikel 19. Exit</h2>
    <ol>
      <li style="list-style-type: '19.1.'">
        <p>
          Na het einde van de Overeenkomst bewaart Summit alle data en gegevens
          van Opdrachtgever voor een periode van 30 dagen. Indien Opdrachtgever
          aan al zijn betalingsverplichtingen op grond van de Overeenkomst heeft
          voldaan, zal Summit zich inspannen een kopie te verstrekken van deze
          data en gegevens in een door Summit gehanteerd standaard
          bestandsformaat.
        </p>
      </li>
    </ol>
    <h2>Artikel 20. Overige bepalingen</h2>
    <ol>
      <li style="list-style-type: '20.1.'">
        <p>
          Op de Overeenkomst is uitsluitend Nederlands recht van toepassing.
        </p>
      </li>
      <li style="list-style-type: '20.2.'">
        <p>
          Voor zover door de regels van dwingend recht niet anders wordt
          voorgeschreven, zullen alle geschillen tussen Partijen in verband met
          de Overeenkomst worden voorgelegd aan de bevoegde Nederlandse rechter
          voor het arrondissement waar Summit gevestigd is.
        </p>
      </li>
      <li style="list-style-type: '20.3.'">
        <p>
          Summit is gerechtigd om zijn rechten en plichten die voortvloeien uit
          de Overeenkomst over te dragen aan een derde partij die Summit of zijn
          bedrijfsactiviteiten overneemt.
        </p>
      </li>
      <li style="list-style-type: '20.4.'">
        <p>
          Indien enige bepaling uit de Overeenkomst nietig blijkt te zijn, tast
          dit niet de geldigheid van de gehele Overeenkomst aan. Partijen zullen
          in dat geval ter vervanging (een) nieuwe bepaling(en) vaststellen,
          waarmee zoveel als rechtens mogelijk is aan de bedoeling van de
          oorspronkelijke bepaling gestalte wordt gegeven.
        </p>
      </li>
      <li style="list-style-type: '20.5.'">
        <p>
          Opdrachtgever dient Summit onverwijld op de hoogte te stellen bij een
          wijziging in naam, (post)adres, e-mailadres, telefoonnummer en overige
          voor de uitvoering van de Overeenkomst relevante informatie en
          gegevens.
        </p>
      </li>
      <li style="list-style-type: '20.6.'">
        <p>
          De door Summit opgeslagen logs en communicatie worden geacht juist te
          zijn, tenzij Opdrachtgever tegenbewijs levert dienaangaande.
        </p>
      </li>
    </ol>
    <h1>Module A | verhuur Hardware</h1>
    <h2>Artikel 1. Verhuur Hardware</h2>
    <p>
      Partijen kunnen overeenkomen dat Opdrachtgever de Hardware van Summit
      huurt. Indien de Overeenkomst (mede) betrekking heeft op de verhuur van de
      Hardware, gelden daarvoor de voorwaarden zoals in deze Module wordt
      omschreven.
    </p>
    <h2>Artikel 2. Hardware</h2>
    <ol>
      <li style="list-style-type: '2.1.'">
        <p>
          Summit verleent hiertoe aan Opdrachtgever voor de duur en onder de
          voorwaarden van de Overeenkomst het recht op gebruik van de Hardware
          zoals vastgelegd in de Overeenkomst.
        </p>
      </li>
      <li style="list-style-type: '2.2.'">
        <p>
          Opdrachtgever is zelf verantwoordelijk voor het verschaffen van de
          stroomvoorziening ten
        </p>
        <p>
          behoeve van de (juiste) werking van de Hardware (“elektriciteit”).
        </p>
      </li>
      <li style="list-style-type: '2.3.'">
        <p>
          Opdrachtgever is verantwoordelijk voor het verschaffen van een
          aansluitmogelijkheid om de
        </p>
        <p>
          Hardware te koppelen aan het internet en/of een lokaal netwerk
          (“netwerkaansluiting”).
        </p>
      </li>
      <li style="list-style-type: '2.4.'">
        <p>
          Enkel Summit is gerechtigd om de Hardware te beheren en om onderhoud
          of enige andere vorm van instandhouding of aanpassing van de Hardware
          uit te voeren.
        </p>
      </li>
      <li style="list-style-type: '2.5.'">
        <p>
          In het geval Opdrachtgever zonder voorafgaande expliciete toestemming
          van Summit wijzigingen aanbrengt in de Hardware is Opdrachtgever
          gehouden de door Summit gemaakte kosten te vergoeden ten behoeve van
          het verhelpen van fouten, problemen of anderszins.
        </p>
      </li>
      <li style="list-style-type: '2.6.'">
        <p>
          Zonder expliciete toestemming van Summit, is Opdrachtgever niet
          gerechtigd om de Hardware te verhuren, onderverhuren of anderszins ter
          beschikking te stellen aan derden.
        </p>
      </li>
    </ol>
    <h2>Artikel 3. Oplevering en installatie</h2>
    <ol>
      <li style="list-style-type: '3.1.'">
        <p>
          Bij gebreke van een uitdrukkelijk door Partijen overeengekomen
          opleveringsdatum zal Summit de Hardware binnen een redelijke termijn
          beschikbaar maken.
        </p>
      </li>
      <li style="list-style-type: '3.2.'">
        <p>
          Summit zal de Hardware opleveren wanneer dit in zijn professionele
          opinie voldoet aan de specificaties en/of geschikt is voor het
          overeengekomen gebruik.
        </p>
      </li>
      <li style="list-style-type: '3.3.'">
        <p>
          Tenzij anders overeengekomen zal de Opdrachtgever de Hardware zelf op
          een door Opdrachtgever te bepalen locatie installeren en aansluiten.
        </p>
      </li>
      <li style="list-style-type: '3.4.'">
        <p>
          Indien overeengekomen zal Summit de Hardware op de in de Overeenkomst
          bepaalde locatie installeren en aansluiten. Summit is gerechtigd de
          installatie van de Hardware op een bepaalde locatie te weigeren indien
          de installatie volgens zijn professionele opinie niet mogelijk is
          en/of de juiste werking van de Hardware niet kan worden gegarandeerd.
        </p>
      </li>
      <li style="list-style-type: '3.5.'">
        <p>
          Summit heeft het recht derden in te schakelen ten behoeve van de
          installatie van de Hardware zoals bedoeld in het vorige lid.
        </p>
      </li>
      <li style="list-style-type: '3.6.'">
        <p>
          Opdrachtgever zal Summit of de door Summit ingeschakelde derde(n)
          toegang verschaffen tot de aangewezen locatie en de noodzakelijke
          medewerking verlenen ten behoeve van de installatie van de Hardware.
        </p>
      </li>
    </ol>
    <h2>Artikel 4. Werking en garanties</h2>
    <ol>
      <li style="list-style-type: '4.1.'">
        <p>
          Opdrachtgever aanvaardt dat de Hardware alleen de functionaliteit en
          overige eigenschappen bevat zoals Opdrachtgever die aantreft in de
          Hardware op het moment van aflevering (“as is”), derhalve met alle
          zichtbare en onzichtbare fouten en gebreken, tenzij schriftelijk
          anders overeengekomen.
        </p>
      </li>
      <li style="list-style-type: '4.2.'">
        <p>
          Summit zal zich inspannen om de Hardware voor Opdrachtgever
          beschikbaar te houden en om eventuele storingen en technische
          problemen zo spoedig mogelijk te verhelpen. Opdrachtgever kan enkel
          aanspraak maken op specifieke service levels, zoals een bepaalde
          beschikbaarheid of reactietijd, indien dit schriftelijk is
          overeengekomen.
        </p>
      </li>
    </ol>
    <h2>Artikel 5. Risico en verzekering</h2>
    <ol>
      <li style="list-style-type: '5.1.'">
        <p>
          Tenietgaan, verlies, slijtage, diefstal of beschadiging van de
          Hardware doet niets af aan de betalingsverplichtingen van
          Opdrachtgever. Verantwoordelijkheid voor eventuele reparaties of
          vervanging kunnen Partijen regelen in een als zodanig aangeduide
          service level agreement.
        </p>
      </li>
      <li style="list-style-type: '5.2.'">
        <p>
          Opdrachtgever dient er zorg voor te dragen dat de Hardware, gedurende
          de Overeenkomst, voor de nieuwwaarde is verzekerd tegen onder andere:
          tenietgaan, verlies, diefstal van of schade aan de Hardware. De met
          deze verzekering verbonden kosten komen geheel voor rekening voor de
          Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '5.3.'">
        <p>
          Opdrachtgever dient er zorg voor te dragen dat Summit, in het kader
          van de voornoemde verzekering, is opgenomen als derde-begunstigde c.q.
          medeverzekerde. Indien het voornoemde redelijkerwijs niet mogelijk is,
          zal Opdrachtgever haar vordering op de betreffende verzekeraar cederen
          aan Summit.
        </p>
      </li>
      <li style="list-style-type: '5.4.'">
        <p>
          Op verzoek van Summit zal Opdrachtgever kopieën van de betreffende
          verzekeringspolis verstrekken en/of bewijs leveren van volledige en
          tijdige premiebetaling.
        </p>
      </li>
      <li style="list-style-type: '5.5.'">
        <p>
          Summit is in geen geval aansprakelijk voor schade ontstaan als het
          gevolg van het niet (juist) functioneren van de Hardware, tenzij er
          sprake is van opzet of grove schuld van de bedrijfsleiding van Summit.
        </p>
      </li>
      <li style="list-style-type: '5.6.'">
        <p>
          Opdrachtgever vrijwaart Summit voor alle aanspraken van derden
          (waaronder klanten van Opdrachtgever), ter zake van vergoeding van
          schade, kosten of rente, verband houdende met de Hardware,
          Overeenkomst en/of de Diensten.
        </p>
      </li>
    </ol>
    <h2>Artikel 6. Eigendom van de Hardware</h2>
    <ol>
      <li style="list-style-type: '6.1.'">
        <p>
          De Hardware is en blijft eigendom van Summit. Niets in de Overeenkomst
          is bedoeld om het eigendom op de Hardware over te dragen aan
          Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '6.2.'">
        <p>
          Summit zorgt ervoor dat de Hardware te allen tijde herkenbaar is als
          eigendom van Summit (bijvoorbeeld door het gebruik van labels). Deze
          markeringen en/of labels mogen niet door Opdrachtgever worden
          verwijderd.
        </p>
      </li>
      <li style="list-style-type: '6.3.'">
        <p>
          Indien derden aanspraak maken op de Hardware of daar beslag op leggen,
          zal Opdrachtgever deze derden wijzen op het eigendomsrecht van Summit
          en Summit zo snel mogelijk (zowel schriftelijk als telefonisch)
          informeren over de aanspraak. In dit geval zal Opdrachtgever Summit in
          staat stellen om de Hardware onverwijld bij Opdrachtgever op te halen.
        </p>
      </li>
      <li style="list-style-type: '6.4.'">
        <p>
          De voornoemde (informatie)plicht geldt ook indien Opdrachtgever het
          vermoeden heeft of redelijkerwijs moet vermoeden dat derden aanspraak
          zullen maken op de Hardware of daar beslag op zullen leggen. Een
          dergelijk vermoeden bestaat in ieder geval indien Opdrachtgever haar
          crediteuren (waaronder mede verstaan de Belastingdienst) niet meer kan
          voldoen of weet dat zij op korte termijn haar crediteuren niet meer
          zal kunnen voldoen.
        </p>
      </li>
      <li style="list-style-type: '6.5.'">
        <p>
          Indien beslag is gelegd op de Hardware door crediteuren van
          Opdrachtgever of vanwege een geschil waarbij Opdrachtgever betrokken
          is, dan ontslaat dit Opdrachtgever niet van haar
          betalingsverplichtingen.
        </p>
      </li>
    </ol>
    <h2>Artikel 7. Retourneren</h2>
    <ol>
      <li style="list-style-type: '7.1.'">
        <p>
          Indien de Overeenkomst wordt beëindigd of ontbonden, zal Opdrachtgever
          de door Summit geleverde Hardware zo spoedig mogelijk conform
          instructies van Summit en binnen vijf (5) werkdagen retourneren aan
          Summit.
        </p>
      </li>
      <li style="list-style-type: '7.2.'">
        <p>
          De kosten voor retournering van de Hardware om welke redenen dan ook
          komen voor rekening van Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '7.3.'">
        <p>
          Opdrachtgever draagt zorg voor de juiste verpakking van de Hardware en
          verzending middels verzekerd transport.
        </p>
      </li>
      <li style="list-style-type: '7.4.'">
        <p>
          Indien bij ontvangst van de Hardware door Summit blijkt dat de
          Hardware in verminderde staat verkeerd, is Summit gerechtigd de kosten
          voor reparatie in rekening te brengen bij Opdrachtgever.
        </p>
      </li>
    </ol>
    <h2>Artikel 8. Uitsluiting</h2>
    <ol>
      <li style="list-style-type: '8.1.'">
        <p>
          Voor zover wettelijk is toegestaan, komen Partijen overeen dat de
          artikelen 7:203 tot en met 7:211 van het Burgerlijk Wetboek zijn
          uitgesloten.
        </p>
      </li>
    </ol>
    <h1>Module B | verkoop Hardware</h1>
    <h2>Artikel 1. Verkoop Hardware</h2>
    <p>
      Partijen kunnen overeenkomen dat Opdrachtgever Hardware koopt van Summit.
      Indien de Overeenkomst (mede) betrekking heeft op de verkoop van Hardware,
      gelden daarvoor de voorwaarden zoals in deze Module wordt omschreven.
    </p>
    <h2>Artikel 2. Levering van Hardware</h2>
    <ol>
      <li style="list-style-type: '2.1.'">
        <p>
          Tenzij anders overeengekomen, wordt Hardware door Summit af fabriek
          geleverd. Het risico op verlies of beschadiging van Hardware gaat over
          op Opdrachtgever op het moment van juridische en/of feitelijke
          levering.
        </p>
      </li>
      <li style="list-style-type: '2.2.'">
        <p>
          Opdrachtgever zal geleverde Hardware zo spoedig mogelijk, maar in
          ieder geval binnen 7 kalenderdagen, controleren op eventuele
          beschadigingen of andere gebreken. Indien Opdrachtgever beschadigingen
          of andere gebreken vaststelt, zal Opdrachtgever bewijs van de
          beschadigingen of andere gebreken vastleggen en Summit hier direct
          over informeren. Een melding van beschadigingen of andere gebreken
          schort de betalingsverplichting van Opdrachtgever niet op.
        </p>
      </li>
      <li style="list-style-type: '2.3.'">
        <p>
          Indien Opdrachtgever binnen de voornoemde termijn geen beschadigingen
          of andere gebreken kenbaar maakt aan Summit, wordt de Hardware geacht
          in goede staat te zijn ontvangen.
        </p>
      </li>
      <li style="list-style-type: '2.4.'">
        <p>
          Op de door Opdrachtgever bij Summit gekochte Hardware rust
          fabrieksgarantie. Informatie over wat fabrieksgarantie inhoudt, staat
          op de website dan wel de bijgeleverde documentatie van de fabrikant.
        </p>
      </li>
      <li style="list-style-type: '2.5.'">
        <p>
          Summit zal haar wettelijke verplichting in het kader van conformiteit
          van geleverde Hardware nakomen, maar verleent Opdrachtgever geen
          (extra) garantie op door haar geleverde Hardware.
        </p>
      </li>
      <li style="list-style-type: '2.6.'">
        <p>De (wettelijke) garantie geldt niet:</p>
        <ol>
          <li style="list-style-type: '(1)'">
            <p>
              indien de fouten het gevolg zijn van onoordeelkundig gebruik of
              van andere oorzaken dan ondeugdelijkheid van materiaal of
              fabricage;
            </p>
          </li>
          <li style="list-style-type: '(2)'">
            <p>
              indien gegeven instructies, voor het gebruik van de Hardware, niet
              zijn nagekomen;
            </p>
          </li>
          <li style="list-style-type: '(3)'">
            <p>
              voor de toestand van de Hardware, ontstaan ten gevolge van
              ondeskundig opslaan, of klimatologische of andere inwerkingen.
            </p>
          </li>
        </ol>
      </li>
      <li style="list-style-type: '2.7.'">
        <p>
          Het retourneren van Hardware is enkel mogelijk na voorafgaande
          toestemming van Summit en met inachtneming van de procedures en
          instructies van Summit. Indien Opdrachtgever Hardware wil retourneren
          aan Summit, dient Opdrachtgever de Hardware zoveel mogelijk in de
          oorspronkelijke staat van levering aan Summit te retourneren. Indien
          er kosten verbonden zijn aan het retourneren van Hardware, dan zijn
          die voor rekening van Opdrachtgever.
        </p>
      </li>
      <li style="list-style-type: '2.8.'">
        <p>
          Indien Summit gehouden is om reparaties of vervangende leveringen uit
          te voeren, dan wordt Summit altijd een redelijke termijn gegund om
          dergelijke reparaties of vervangende leveringen uit te voeren.
        </p>
      </li>
    </ol>
    <h2>Artikel 3. Eigendomsvoorbehoud</h2>
    <ol>
      <li style="list-style-type: '3.1.'">
        <p>
          Alle Hardware die Summit aan Opdrachtgever levert, blijft eigendom van
          Summit, tot het moment waarop Opdrachtgever al haar
          betaalverplichtingen jegens Summit deugdelijk is nagekomen.
        </p>
      </li>
      <li style="list-style-type: '3.2.'">
        <p>
          Alle door Summit geleverde Hardware, die onder het eigendomsvoorbehoud
          uit het vorige lid van dit artikel vallen, mogen door Opdrachtgever
          niet worden doorverkocht, als betaalmiddel worden gebruikt, worden
          verpand of op enige andere wijze bezwaard.
        </p>
      </li>
      <li style="list-style-type: '3.3.'">
        <p>
          Opdrachtgever dient al hetgeen te doen dat redelijkerwijs van haar
          verwacht mag worden om de eigendomsrechten van Summit veilig te
          stellen.
        </p>
      </li>
      <li style="list-style-type: '3.4.'">
        <p>
          Indien een derde partij beslag wil (laten) leggen op Hardware die
          onder eigendomsvoorbehoud is geleverd, dan wel daarop rechten wil
          vestigen of laten gelden, dan stelt Opdrachtgever Summit daarvan
          direct op de hoogte.
        </p>
      </li>
      <li style="list-style-type: '3.5.'">
        <p>
          Opdrachtgever geeft bij voorbaat uitdrukkelijke en onvoorwaardelijke
          toestemming aan Summit en door Summit aan te wijzen derden om de
          plaatsen, waar de eigendommen van Summit zich bevinden, te betreden en
          de Hardware terug te nemen indien Opdrachtgever haar
          betalingsverplichting niet nakomt.
        </p>
      </li>
    </ol>
    <h1>Bijlage 1. Verwerkersovereenkomst</h1>
    <p>
      De Verwerkersovereenkomst in deze Bijlage 1 en de daarin opgenomen
      afspraken hebben betrekking op de verwerking van persoonsgegevens van
      Opdrachtgever door Summit.
    </p>
    <h2>ARTIKEL 1. DEFINITIES</h2>
    <p>
      De in deze Verwerkersovereenkomst opgenomen woorden (zoals
      “persoonsgegevens”, “verwerken” en “betrokkene”) hebben, in welke
      vervoeging dan ook, de betekenis zoals bedoeld in de toepasselijke
      (privacy) wet- en regelgeving, waaronder de AVG. Daarnaast hebben de
      onderstaande woorden in deze Verwerkersovereenkomst, steeds aangeduid met
      een hoofdletter, zowel in enkelvoud als in meervoud, de betekenis zoals
      beschreven in dit artikel.
    </p>
    <ol>
      <li style="list-style-type: '1.1.'">
        <p>
          AVG: verordening (EU) 2016/679, ook wel de Algemene Verordening
          Gegevensbescherming genoemd.
        </p>
      </li>
      <li style="list-style-type: '1.2.'">
        <p>
          Datalek: een inbreuk op de beveiliging die per ongeluk of op
          onrechtmatige wijze leidt tot de vernietiging, het verlies, de
          wijziging of de ongeoorloofde verstrekking van of de ongeoorloofde
          toegang tot doorgezonden, opgeslagen of anderszins verwerkte gegevens.
        </p>
      </li>
      <li style="list-style-type: '1.3.'">
        <p>
          Dienst(en): de diensten die Verwerker op grond van de Overeenkomst
          levert aan Verantwoordelijke.
        </p>
      </li>
      <li style="list-style-type: '1.4.'">
        <p>Verantwoordelijke: Opdrachtgever.</p>
      </li>
      <li style="list-style-type: '1.5.'">
        <p>Verwerker: Summit.</p>
      </li>
    </ol>
    <h2>ARTIKEL 2. DOELEINDEN VAN DE VERWERKING</h2>
    <ol>
      <li style="list-style-type: '2.1.'">
        <p>
          Verwerker verbindt zich onder de voorwaarden van deze
          Verwerkersovereenkomst in opdracht van Verantwoordelijke
          persoonsgegevens te verwerken. Verwerking zal uitsluitend plaatsvinden
          in het kader van de Overeenkomst en een goede uitvoering van de daarin
          overeengekomen Diensten ten behoeve van Verantwoordelijke, en die
          doeleinden die daar redelijkerwijs mee samenhangen of die met nadere
          instemming worden bepaald.
        </p>
      </li>
      <li style="list-style-type: '2.2.'">
        <p>
          De persoonsgegevens die door Verwerker in het kader van de
          Overeenkomst (zullen) worden verwerkt, en de categorieën betrokkenen
          van wie deze afkomstig zijn, zijn opgenomen in bijlage
        </p>
        <p>
          2. Verwerker zal de persoonsgegevens niet voor enig ander doel
          verwerken dan zoals door de Verantwoordelijke is vastgesteld. De
          Verantwoordelijke zal Verwerker op de hoogte stellen van de
          verwerkingsdoeleinden voor zover deze niet reeds in deze
          Verwerkersovereenkomst zijn genoemd.
        </p>
      </li>
      <li style="list-style-type: '2.3.'">
        <p>
          Eventuele (eigendoms)rechten op de persoonsgegevens die Verwerker voor
          de Verantwoordelijke verwerkt, blijven rusten bij de Verantwoordelijke
          en/of de betreffende betrokkenen.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 3. VERPLICHTINGEN VERWERKER</h2>
    <ol>
      <li style="list-style-type: '3.1.'">
        <p>
          Ten aanzien van de in artikel 2 genoemde verwerkingen zal Verwerker
          zich naar beste kunnen inspannen om de op Verwerker van toepassing
          zijnde wet- en regelgeving op het gebied van de bescherming van
          persoonsgegevens na te leven. Verwerker zal de Verantwoordelijke, op
          diens eerste verzoek daartoe, informeren over de door haar genomen
          maatregelen aangaande haar verplichtingen onder deze
          Verwerkersovereenkomst.
        </p>
      </li>
      <li style="list-style-type: '3.2.'">
        <p>
          De verplichtingen van Verwerker die uit deze Verwerkersovereenkomst
          voortvloeien, gelden ook voor degenen die persoonsgegevens verwerken
          onder het gezag van Verwerker, waaronder begrepen maar niet beperkt
          tot werknemers.
        </p>
      </li>
      <li style="list-style-type: '3.3.'">
        <p>
          Verwerker zal de Verantwoordelijke de noodzakelijke en redelijkerwijs
          mogelijke medewerking verlenen wanneer er in het kader van de
          verwerking een gegevensbeschermingseffectbeoordeling (ook wel een
          ‘Privacy Impact Assessment’ genoemd) noodzakelijk is. Tevens zal
          Verwerker op verzoek van Verantwoordelijke redelijkerwijs medewerking
          verlenen aan het afhandelen van een verzoek van een betrokkene zoals
          bedoeld in artikel 8. Eventuele kosten die Verwerker in dit kader moet
          maken komen redelijkerwijs voor rekening van de Verantwoordelijke.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 4. DOORGIFTE VAN PERSOONSGEGEVENS</h2>
    <ol>
      <li style="list-style-type: '4.1.'">
        <p>
          Verantwoordelijke geeft Verwerker toestemming om, in het kader van de
          uitvoering van de Hoofd- of Verwerkersovereenkomst, de
          persoonsgegevens te laten verwerken door derden (ook wel
          onderaannemers of sub-verwerkers genoemd). Onder deze toestemming valt
          in ieder geval de toestemming aan Verwerker om de persoonsgegevens te
          laten verwerken door een geschikte derde die de Dienst van Verwerker
          zal hosten. Op verzoek van de Verantwoordelijke zal Verwerker de
          Verantwoordelijke informeren over de door haar ingeschakelde derden.
        </p>
      </li>
      <li style="list-style-type: '4.2.'">
        <p>
          Verwerker verwerkt de persoonsgegevens uitsluitend binnen de landen in
          de Europese Economische Ruimte (EER), of bij derden die zijn gevestigd
          in landen met een passend beschermingsniveau zoals erkend door de
          Europese Commissie. Op verzoek van Verantwoordelijke, zal Verwerker
          melden om welk land of landen het gaat.
        </p>
      </li>
      <li style="list-style-type: '4.3.'">
        <p>
          Indien Verwerker de intentie heeft om nieuwe derden in te schakelen in
          het kader van het verwerken van persoonsgegevens onder deze
          Verwerkersovereenkomst, dan zal Verwerker de Verantwoordelijke
          hierover informeren op eigen initiatief. Vervolgens heeft de
          Verantwoordelijke twee weken de tijd om bezwaar te maken tegen het
          inschakelen van de nieuwe derde. Dit bezwaar dient schriftelijk en
          door argumenten ondersteund te worden ingediend.
        </p>
      </li>
      <li style="list-style-type: '4.4.'">
        <p>
          Indien de Verantwoordelijke bezwaar maakt zoals hiervoor bedoeld, dan
          zullen Partijen in goed overleg op zoek gaan naar een redelijke
          oplossing. Mocht het voorkomen dat Partijen gezamenlijk niet tot een
          oplossing kunnen komen, dan is Verwerker gerechtigd om de nieuwe
          derden alsnog in te schakelen. In dit geval is de Verantwoordelijke
          gerechtigd om de Hoofd- en Verwerkersovereenkomst op te zeggen tegen
          de datum waarop de betreffende nieuwe derde door Verwerker wordt
          ingeschakeld.
        </p>
      </li>
      <li style="list-style-type: '4.5.'">
        <p>
          Indien de Verantwoordelijke geen bezwaar maakt binnen de termijn van
          twee weken zoals bedoeld in artikel 4.3., dan is Verwerker gerechtigd
          om de betreffende nieuwe derde in te schakelen.
        </p>
      </li>
      <li style="list-style-type: '4.6.'">
        <p>
          Indien Verwerker een derde inschakelt bij het verwerken van
          persoonsgegevens, zal Verwerker zich inspannen om deze derde voor
          zover relevant wezenlijk dezelfde verplichtingen op te leggen als de
          verplichtingen die voor Verwerker uit deze Verwerkersovereenkomst
          voortvloeien.
        </p>
      </li>
      <li style="list-style-type: '4.7.'">
        <p>
          Verwerker is verantwoordelijk voor de derden die zij op eigen
          initiatief heeft ingeschakeld en is bij fouten van deze derden zelf
          jegens de Verantwoordelijke aansprakelijk voor schade alsof Verwerker
          zelf de fout(en) heeft begaan.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 5. VERDELING VAN VERANTWOORDELIJKHEID</h2>
    <ol>
      <li style="list-style-type: '5.1.'">
        <p>
          Onder de uitdrukkelijke (eind)verantwoordelijkheid van
          Verantwoordelijke en binnen de doeleinden zoals omschreven in artikel
          2, is Verwerker louter verantwoordelijk voor de verwerking van
          persoonsgegevens via de door haar onder de Overeenkomst aangeboden
          Dienst. Voor de overige verwerkingen van persoonsgegevens, waaronder
          in ieder geval begrepen maar niet beperkt tot de verzameling van de
          persoonsgegevens door de Verantwoordelijke en/of derden, verwerkingen
          voor doeleinden die niet door Verantwoordelijke aan Verwerker zijn
          gemeld, verwerkingen door derden en/of voor andere doeleinden, is
          Verwerker uitdrukkelijk niet verantwoordelijk.
        </p>
      </li>
      <li style="list-style-type: '5.2.'">
        <p>
          De verantwoordelijkheid voor de persoonsgegevens die met gebruikmaking
          van een door Verwerker verleende Dienst worden verwerkt, ligt bij
          Verantwoordelijke. Het is aan de Verantwoordelijke om te bepalen of
          zij de persoonsgegevens onder de overeengekomen voorwaarden door
          Verwerker mag laten verwerken. De Verantwoordelijke garandeert dat de
          inhoud, het gebruik en de opdracht tot de verwerkingen van de
          persoonsgegevens zoals bedoeld in de Hoofd- en Verwerkersovereenkomst,
          niet onrechtmatig zijn en geen inbreuk maken op enig recht van derden.
          De Verantwoordelijke vrijwaart Verwerker voor alle aanspraken van
          derden die voortvloeien uit het niet voldoen aan de voornoemde
          garantie door de Verantwoordelijke.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 6. BEVEILIGING</h2>
    <ol>
      <li style="list-style-type: '6.1.'">
        <p>
          Verwerker zal zich inspannen passende technische en organisatorische
          maatregelen te nemen met betrekking tot de te verrichten verwerkingen
          van persoonsgegevens, teneinde de persoonsgegevens te beschermen tegen
          verlies of tegen enige vorm van onrechtmatige verwerking (zoals
          onbevoegde kennisname, aantasting, wijziging of verstrekking van de
          persoonsgegevens).
        </p>
      </li>
      <li style="list-style-type: '6.2.'">
        <p>
          Partijen erkennen dat het onmogelijk is om beveiligingsmaatregelen te
          treffen die onder alle omstandigheden doeltreffend zijn. Verwerker zal
          zich er naar beste kunnen voor inspannen dat de
          beveiligingsmaatregelen voldoen aan een niveau dat, gelet op de stand
          van de techniek, de gevoeligheid van de persoonsgegevens en de aan het
          treffen van de beveiligingsmaatregelen verbonden kosten, passend is.
        </p>
      </li>
      <li style="list-style-type: '6.3.'">
        <p>
          Verwerker zal in bijlage 2 beschrijven welke (groepen) medewerkers
          toegang tot de persoonsgegevens hebben en welke verwerkingen door de
          betreffende medewerkers zijn toegestaan.
        </p>
      </li>
      <li style="list-style-type: '6.4.'">
        <p>
          Verantwoordelijke beslist welke persoonsgegevens precies door
          Verwerker verwerkt worden en stelt enkel persoonsgegevens aan
          Verwerker ter beschikking voor verwerking, indien Verantwoordelijke
          zich ervan heeft verzekerd dat de volgens Verantwoordelijke vereiste
          beveiligingsmaatregelen zijn getroffen.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 7. MELDPLICHT</h2>
    <ol>
      <li style="list-style-type: '7.1.'">
        <p>
          Indien Verwerker een Datalek heeft geconstateerd, dan zal Verwerker de
          Verantwoordelijke daarover onverwijld, maar in ieder geval binnen 48
          uur, informeren, naar aanleiding waarvan de Verantwoordelijke
          beoordeelt of zij de betrokkene(n) en/of de relevante
          toezichthouder(s) zal informeren of niet.
        </p>
      </li>
      <li style="list-style-type: '7.2.'">
        <p>
          Indien Verwerker een Datalek heeft geconstateerd, dan zal Verwerker
          alle redelijke maatregelen treffen om (verdere) onrechtmatige
          verwerking te voorkomen of te beperken.
        </p>
      </li>
      <li style="list-style-type: '7.3.'">
        <p>
          De kennisgeving van Verwerker aan de Verantwoordelijke omvat het feit
          dat er een Datalek is geweest. Op verzoek van Verantwoordelijke
          verstrekt Verwerker de procedure die Verwerker hanteert ten aanzien
          van de omgang met een Datalek alsmede de gegevens die Verwerker zal
          verschaffen.
        </p>
      </li>
      <li style="list-style-type: '7.4.'">
        <p>
          Op verzoek van Verantwoordelijke en indien nodig zal Verwerker
          redelijkerwijs meewerken aan het informeren van de betrokkene(n) en/of
          de relevante toezichthouder(s).
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 8. AFHANDELING VERZOEKEN VAN BETROKKENEN</h2>
    <ol>
      <li style="list-style-type: '8.1.'">
        <p>
          In het geval dat een betrokkene een verzoek tot inzage, verbetering,
          aanvulling, wijziging of afscherming van zijn persoonsgegevens richt
          aan Verwerker, zal Verwerker het verzoek doorsturen aan
          Verantwoordelijke, en zal Verantwoordelijke het verzoek verder
          afhandelen. Verwerker mag de betrokkene hiervan op de hoogte stellen.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 9. GHEIMHOUDING EN VERTROUWELIJKHEID</h2>
    <ol>
      <li style="list-style-type: '9.1.'">
        <p>
          Op alle persoonsgegevens die Verwerker in opdracht van
          Verantwoordelijke verwerkt rust een geheimhoudingsplicht jegens
          derden.
        </p>
      </li>
      <li style="list-style-type: '9.2.'">
        <p>
          Deze geheimhoudingsplicht is niet van toepassing: (i) voor zover de
          Verantwoordelijke uitdrukkelijke toestemming heeft gegeven om de
          persoonsgegevens aan derden te verschaffen,
        </p>
        <p>
          (ii) indien het verstrekken van de persoonsgegevens aan derden
          logischerwijs noodzakelijk is gezien de aard van de verstrekte
          opdracht en de uitvoering van de Hoofd – en Verwerkersovereenkomst,
          (iii) indien persoonsgegevens worden verstrekt aan derden in hun
          hoedanigheid als onderaannemer / sub-verwerker, of (iv) indien er een
          wettelijke verplichting bestaat om de persoonsgegevens aan een derde
          te verstrekken.
        </p>
      </li>
      <li style="list-style-type: '9.3.'">
        <p>
          Indien Verwerker op grond van een wettelijke verplichting gehouden is
          om persoonsgegevens aan een derde te verstrekken, zal zij de
          Verantwoordelijke hiervan vooraf op de hoogte stellen en de
          Verantwoordelijke redelijkerwijs de tijd gunnen om rechtsmiddelen in
          te zetten teneinde het verstrekken van persoonsgegevens tegen te gaan,
          tenzij dit verboden is onder de betreffende wetgeving.
        </p>
      </li>
      <li style="list-style-type: '9.4.'">
        <p>
          Indien Verwerker van de diensten van derden gebruik maakt, zorgt
          Verwerker ervoor dat deze derden ook een geheimhoudingsplicht
          overeenkomen met Verwerker.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 10. AUDIT</h2>
    <ol>
      <li style="list-style-type: '10.1.'">
        <p>
          Verantwoordelijke heeft het recht om audits te laten uitvoeren door
          een onafhankelijke ICT-deskundige ter controle van naleving van alle
          punten uit deze Verwerkersovereenkomst.
        </p>
      </li>
      <li style="list-style-type: '10.2.'">
        <p>
          Deze audit mag eens per twee jaar plaatsvinden. Minimaal twee weken
          voorafgaande aan de voorgenomen audit, zal de Verantwoordelijke
          Verwerker hierover informeren.
        </p>
      </li>
      <li style="list-style-type: '10.3.'">
        <p>
          De onafhankelijke ICT-deskundige die de audit zal uitvoeren dient zich
          te conformeren aan de beveiligingsprocedures zoals die bij Verwerker
          van kracht zijn. Voorts moet een adequate geheimhouding met deze
          ICT-deskundige overeengekomen te worden.
        </p>
      </li>
      <li style="list-style-type: '10.4.'">
        <p>
          Verwerker zal aan de audit meewerken en alle voor de audit
          redelijkerwijs relevante informatie, inclusief ondersteunende gegevens
          zoals systeemlogs, en medewerkers zo tijdig mogelijk ter beschikking
          stellen.
        </p>
      </li>
      <li style="list-style-type: '10.5.'">
        <p>
          De bevindingen naar aanleiding van de uitgevoerde audit zullen door
          Partijen in onderling overleg worden beoordeeld en, naar aanleiding
          daarvan, al dan niet worden doorgevoerd door één van de Partijen of
          door beide Partijen gezamenlijk.
        </p>
      </li>
      <li style="list-style-type: '10.6.'">
        <p>
          Alle kosten in verband met een audit worden door Verantwoordelijke
          gedragen.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 11. WIJZIGING</h2>
    <ol>
      <li style="list-style-type: '11.1.'">
        <p>
          Indien een wijziging in de te verwerken persoonsgegevens of een
          risicoanalyse van de verwerking van persoonsgegevens daartoe
          aanleiding geeft, treden Partijen op eerste verzoek van
          Verantwoordelijke in overleg over het aanpassen van de gemaakte
          afspraken binnen deze Verwerkersovereenkomst.
        </p>
      </li>
      <li style="list-style-type: '11.2.'">
        <p>
          Deze Verwerkersovereenkomst kan enkel schriftelijk en met instemming
          van beide Partijen gewijzigd worden. Wanneer Partijen nieuwe afspraken
          zijn overeengekomen, dan zullen Partijen deze nieuwe afspraken
          toevoegen aan deze Verwerkersovereenkomst.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 12. DUUR EN BEËINDIGING</h2>
    <ol>
      <li style="list-style-type: '12.1.'">
        <p>
          Deze Verwerkersovereenkomst komt tot stand door ondertekening van de
          Overeenkomst door Partijen en op de datum van de laatste
          ondertekening.
        </p>
      </li>
      <li style="list-style-type: '12.2.'">
        <p>
          De duur van de Verwerkersovereenkomst is gelijk aan de duur van de
          Overeenkomst. De Verwerkersovereenkomst is niet los van de
          Overeenkomst te beëindigen. Wanneer de Overeenkomst om welke reden dan
          ook ten einde komt, dan komt deze Verwerkersovereenkomst ook
          automatisch ten einde.
        </p>
      </li>
      <li style="list-style-type: '12.3.'">
        <p>
          Bij beëindiging van de Verwerkersovereenkomst om welke reden ook zal
          Verwerker op verzoek:
        </p>
        <ol>
          <li style="list-style-type: 'a.'">
            <p>
              de persoonsgegevens, die Verwerker in opdracht van
              Verantwoordelijke heeft verwerkt en waarover Verwerker nog
              beschikt, in originele of kopievorm ter beschikking stellen aan de
              Verantwoordelijke in het bestandsformaat waarin het bij Verwerker
              was opgeslagen; of
            </p>
          </li>
          <li style="list-style-type: 'b.'">
            <p>
              de persoonsgegevens, die Verwerker in opdracht van
              Verantwoordelijke heeft verwerkt en waarover Verwerker nog
              beschikt, vernietigen.
            </p>
          </li>
        </ol>
      </li>
      <li style="list-style-type: '12.4.'">
        <p>
          Partijen zullen zich inspannen om, voor zover redelijkerwijs mogelijk,
          voorafgaand aan de werkzaamheden zoals genoemd in artikel 12.3. een
          redelijke vergoeding voor de betreffende werkzaamheden overeen te
          komen. Wanneer Partijen geen vergoeding zijn overeengekomen, is
          Verwerker gerechtigd om een marktconforme vergoeding in rekening te
          brengen bij Verantwoordelijke.
        </p>
      </li>
      <li style="list-style-type: '12.5.'">
        <p>
          Indien Verantwoordelijke geen verzoek indient bij Verwerker om één van
          de werkzaamheden zoals genoemd in artikel 12.3. uit te voeren, dan is
          Verwerker vanaf één maand na het ten einde komen van de
          Verwerkersovereenkomst gerechtigd om de betreffende persoonsgegevens
          van haar systemen te verwijderen.
        </p>
      </li>
      <li style="list-style-type: '12.6.'">
        <p>
          Verplichtingen welke naar hun aard bestemd zijn om ook na het ten
          einde komen van deze Verwerkersovereenkomst voort te duren, blijven na
          het ten einde komen van deze Verwerkersovereenkomst gelden. Tot deze
          bepalingen behoren onder meer die welke voortvloeien uit de bepalingen
          betreffende geheimhouding en toepasselijk recht.
        </p>
      </li>
    </ol>
    <h2>ARTIKEL 13. OVERIGE</h2>
    <ol>
      <li style="list-style-type: '13.1.'">
        <p>
          Deze Verwerkersovereenkomst maakt integraal onderdeel uit van en wordt
          aangevuld door de Overeenkomst. Dit betekent onder meer dat de
          beperking van aansprakelijkheid, zoals overeengekomen in de
          Overeenkomst en de bijbehorende algemene voorwaarden van Verwerker,
          mede van toepassing is op deze Verwerkersovereenkomst.
        </p>
      </li>
      <li style="list-style-type: '13.2.'">
        <p>
          Logs en metingen gedaan door Verwerker gelden als dwingend bewijs,
          behoudens tegenbewijs te leveren door Verantwoordelijke.
        </p>
      </li>
    </ol>
    <h1>Bijlage 2. Specificatie persoonsgegevens</h1>
    <h2>De te verwerken (categorieën) persoonsgegevens</h2>
    <p>
      De volgende (categorieën) persoonsgegevens verwerkt Verwerker in opdracht
      van Verantwoordelijke.
    </p>
    <table>
      <tr>
        <td>Functie(groep)</td>
        <td>(Categorie) Persoonsgegevens</td>
        <td>Type verwerking</td>
      </tr>
      <tr>
        <td>NAW-gegevens</td>
        <td>Naam, adres en woonplaats</td>
        <td>Vastleggen, bewaren en gebruiken voor analyse en follow-up</td>
      </tr>
      <tr>
        <td>Contactgegevens</td>
        <td>Telefoonnummer, e-mailadres</td>
        <td>Vastleggen, bewaren en gebruiken voor analyse en follow-up</td>
      </tr>
      <tr>
        <td>Profiel-gegevens</td>
        <td>Bijvoorbeeld: functie, vooropleiding</td>
        <td>Vastleggen, bewaren en gebruiken voor analyse en follow-up</td>
      </tr>
      <tr>
        <td>Interesse</td>
        <td>Bijvoorbeeld: sessiekeuze, opleidingsinteresse</td>
        <td>Vastleggen, bewaren en gebruiken voor analyse en follow-up</td>
      </tr>
      <tr>
        <td>Overige identificerende gegevens</td>
        <td>Geslacht, geboortedatum</td>
        <td>Vastleggen, bewaren en gebruiken voor analyse en follow-up</td>
      </tr>
    </table>
    <p style="margin-top: 0.5em">
      Voor alle hierboven gemelde gegevens geldt dat de Verantwoordelijke per
      registratie van een activiteit, bijeenkomst of evenement bepaalt of deze
      gegevens worden gevraagd en, indien zij worden gevraagd, deze al dan niet
      optioneel beantwoord hoeven te worden door de gebruiker.
    </p>
    <p style="margin-top: 0.5em">
      Er worden geen bijzondere persoonsgegevens (zoals persoonsgegevens met
      betrekking tot religie, seksuele geaardheid, gezondheid of financiën)
      verwerkt.
    </p>
    <p style="margin-top: 0.5em">
      Persoonsgegevens worden door Verwerker niet langer bewaard dan
      noodzakelijk is voor de uitvoering van de Hoofd- en Verwerkersovereenkomst
      of om een op hem rustende wettelijke verplichting na te komen. Voor
      persoonsgegevens die worden verwerkt ten behoeve van de juiste werking van
      de Dienst (logging, back-up voorzieningen etc.) gelden de volgende
      bewaartermijnen:
    </p>
    <ul style="margin-top: 0">
      <li>
        <p>1 jaar, tenzij Partijen anders overeenkomen.</p>
      </li>
    </ul>
    <h2>Categorieën betrokkenen</h2>
    <p>
      De eerder genoemde persoonsgegevens worden verwerkt van de volgende
      categorieën betrokkenen:
    </p>
    <ul style="margin-top: 0">
      <li style="list-style-type: '-'">
        <p>medewerkers van Verantwoordelijke die de Dienst gebruiken;</p>
      </li>
      <li style="list-style-type: '-'">
        <p>bezoekers van een evenement georganiseerd door Verantwoordelijke;</p>
      </li>
      <li style="list-style-type: '-'">
        <p>
          personen die geïnteresseerd zijn in of zich ingeschreven hebben voor
          het evenement van Verantwoordelijke.
        </p>
      </li>
      <li style="list-style-type: '-'">
        <p>
          Personen (studiekiezers) die geïnteresseerd zijn in het volgen van een
          opleiding bij Verantwoordelijke
        </p>
      </li>
    </ul>
    <h2>Functierollen/ functiegroepen en hun verwerkingen</h2>
    <p>
      In de volgende tabel staan de functierollen en/of functiegroepen van de
      medewerkers van Verwerker en Verantwoordelijke vermeld die toegang hebben
      tot bepaalde persoonsgegevens. Tevens staat vermeld welke verwerkingen zij
      ten aanzien van de persoonsgegevens mogen uitvoeren.
    </p>
    <table>
      <tr>
        <th>Functie(groep)</th>
        <th>(Categorie) Persoonsgegevens</th>
        <th>Type verwerking</th>
      </tr>
      <tr>
        <td>Afdeling Project Management</td>
        <td>Alle persoonsgegevens, zoals vermeld in bijlage A</td>
        <td>
          PM-medewerkers (Project Management) van Verwerker hebben toegang tot
          de persoonsgegevens ten behoeve van de advisering van de medewerkers
          van de Verantwoordelijke bij inhoudelijke vragen en hulp bij de
          analyse van gegevens in opdracht van Verantwoordelijke.
        </td>
      </tr>
      <tr>
        <td>Afdeling IT</td>
        <td>Alle persoonsgegevens, zoals vermeld in bijlage A</td>
        <td>
          IT-medewerkers van Verwerker ten behoeve van het ontwikkelen van
          software, updaten, testen en de admin-functie allen gericht op
          continuïteit en optimalisatie van de systemen. Daarnaast zijn zij
          beschikbaar als technische helpdesk en verantwoordelijk voor de
          totstandkoming en het beheer van eventuele koppelingen.
        </td>
      </tr>
      <tr>
        <td>Geautoriseerde medewerkers Verantwoordelijke</td>
        <td>Alle persoonsgegevens, zoals vermeld in bijlage A</td>
        <td>
          Vanzelfsprekend hebben ook medewerkers van de Verantwoordelijke zelf
          toegang tot de persoonsgegevens. Gebruikers van de Dienst hebben
          toegang tot het dashboard. Hier worden alle gegevens waarvoor zij
          geautoriseerd zijn voor hen ontsloten. Gebruikers zijn altijd
          gekoppeld aan de Verantwoordelijke en kunnen geen toegang krijgen tot
          het dashboard van andere opdrachtgevers van Verwerker. De door de
          Verantwoordelijke aangewezen beheerders van Verwerker hebben de
          machtigingen om te bepalen welke gegevens via het dashboard worden
          ontsloten.
        </td>
      </tr>
    </table>
    <h2>Getroffen beveiligingsmaatregelingen</h2>
    <p>
      Verwerker zal passende beveiligingsmaatregelen nemen en de
      Verantwoordelijke hier, op verzoek, een overzicht van toesturen.
    </p>
    <p>
      In het geval u beveiligingsrisico’s constateert, dan verzoeken wij u
      direct contact op te nemen met
    </p>
    <p>Verwerker (Summit) via 030-2307940.</p>
    <h2>Sub-verwerkers</h2>
    <p>
      Verwerker heeft toestemming van Verantwoordelijke om derden in te zetten
      bij het verwerken van persoonsgegevens. Verwerker schakelt de volgende
      derde(n) in:
    </p>
    <ul>
      <li style="list-style-type: '-'">
        <p>Naam organisatie: Leaseweb.</p>
      </li>
      <li style="list-style-type: '-'">
        <p>
          Korte omschrijving dienstverlening: hostingprovider voor de database
          servers van Verwerker.
        </p>
      </li>
      <li style="list-style-type: '-'">
        <p>
          Mate van verwerking Persoonsgegevens: Leaseweb is enkel
          verantwoordelijk voor de hosting van de servers van Verwerker en is
          verder in op geen enkele manier verder betrokken bij de verwerking van
          persoonsgegevens.
        </p>
      </li>
      <li style="list-style-type: '-'">
        <p>Plaats/Land van verwerking gegevens: Amsterdam, Nederland.</p>
      </li>
    </ul>
    <h2>Contactgegevens</h2>
    <p>
      Verantwoordelijke zal voor het sluiten van de Overeenkomst of zo spoedig
      mogelijk na het sluiten van de Overeenkomst een intern contactpersoon
      aanwijzen waar Verwerker in het kader van privacy gerelateerde zaken en
      deze Verwerkersovereenkomst contact mee op kan nemen. De contactgegevens
      van deze contactpersoon (de naam, functie, het e-mailadres en het
      telefoonnummer) zullen zo spoedig mogelijk schriftelijk aan Verwerker
      verstrekt worden.
    </p>
  </div>
</template>

<style scoped>
.terms {
  font-family: Calibri, sans-serif;
  font-size: 11pt;
  color: black;
}

.terms h1 {
  font-size: 18pt;
  font-weight: bold;
  margin: 1em 0 0.5em 0;
}

.terms h2 {
  font-weight: bold;
  margin: 1em 0 0.25em 0;
}

.terms p {
  margin: 0;
  padding: 0;
}

.terms a {
  color: blue;
  text-decoration: underline;
}

.terms ol {
  margin-top: 0.5em;
}

.terms li {
  padding-left: 0.5em;
  margin-left: 32px;
  list-style-position: outside;
}

.terms table {
  border-collapse: collapse;
  margin: 0.5em 0;
}

.terms table td,
.terms table th {
  border: 1px solid black;
  text-align: left;
}

.terms table th {
  background-color: #a6a6a6;
}

.terms .bold {
  font-weight: bold;
}
</style>
